import { Add } from '@styled-icons/material';
import { Icon, IconProps } from './Icon';

export function AddIcon({ ...props }: IconProps) {
  return (
    <Icon {...props}>
      <Add />
    </Icon>
  );
}
